<template>
  <div>
    <template v-if="automaticCalc">
      <b-field
        v-if="subjectiveUsePercent"
        label="Weight Change"
        horizontal
        class="label-long"
        :class="{ 'has-text-warning': this.weightDiffKg < 0 }"
      >
        <p class="unitLabel">{{ weightChanged }}</p>
      </b-field>
      <div v-else class="columns">
        <div class="column">
          <b-field label="Weight Change" horizontal class="label-long" :class="{ 'has-text-warning': this.weightDiffKg < 0 }">
            <p>{{ weightChanged }}</p>
          </b-field>
        </div>
        <div class="column"></div>
        <div class="column"></div>
      </div>
    </template>
    <template v-else>
      <b-field v-if="subjectiveUsePercent" label="Unplanned Weight Loss" horizontal class="label-long">
        <EnumDropDown :value="weightLossPercemt" @input="updateWeightLossPercent" :data="weightLossEnum" :disabled="disabled" />
      </b-field>
      <div v-else class="columns">
        <div class="column">
          <b-field label="Unplanned Weight Loss" horizontal class="label-long">
            <YesNoUnsurePicker
              :value="unplannedWeightLoss"
              @input="updateUnplannedWeightLoss"
              name="unplannedWeightLoss"
              :message="`(in past ${preferredDays} days)`"
              :disabled="disabled"
            />
          </b-field>
        </div>
        <div class="column">
          <ValidationProvider :rules="weightLossRules" v-slot="{ errors }" v-if="showWeightLoss">
            <b-field label="Weight Loss (kg)" horizontal class="label-long" :type="{ 'is-danger': errors[0] }" :message="errors">
              <b-numberinput :value="weightLoss" @input="updateWeightLoss" :controls="false" step="0.01" required :disabled="disabled" />
            </b-field>
          </ValidationProvider>
        </div>
        <div class="column"></div>
      </div>
    </template>
  </div>
</template>

<script>
import DatesMixin from "@/mixins/datesMixin";
import EnumDropDown from "@/components/EnumDropDown";
import YesNoUnsurePicker from "@/components/YesNoUnsure";
import Weightloss from "@/enums/weightloss";
import YesNoUnsure from "@/enums/yesNoUnsure";
import OrgConfig from "@/enums/orgconfig";

export default {
  mixins: [DatesMixin],
  components: { EnumDropDown, YesNoUnsurePicker },
  props: {
    currentWeight: { type: Number },
    previousWeight: { type: Number },
    previousWeightDate: { type: String },
    unplannedWeightLoss: { type: Number },
    weightLoss: { type: Number },
    weightLossPercemt: { type: Number },
    disabled: { type: Boolean },
    subjectiveUsePercent: { type: Boolean, default: true }
  },
  data() {
    return {
      weightLossEnum: Weightloss.Lookup,
      weightLossRules: { weight: [0, 50] }
    };
  },
  computed: {
    preferredDays() {
      return this.$store.getters["orgconfig/defaults"][OrgConfig.Enum.Obs_WeightLossCalc_Previous_Preferred].value;
    },
    automaticCalc() {
      return !!this.previousWeight;
    },
    weightDiffKg() {
      return this.automaticCalc ? this.currentWeight - this.previousWeight : 0;
    },
    weightDiffPercent() {
      return this.automaticCalc ? Math.round((this.weightDiffKg / this.previousWeight) * 1000, 12) / 10 : 0;
    },
    lossGain() {
      return this.weightDiffKg < 0 ? "Lost" : "Gained";
    },
    weightChanged() {
      return `${Math.abs(this.weightDiffKg)} kg (${Math.abs(this.weightDiffPercent)}%) ${this.lossGain} since ${this.shortFormatDate(
        this.previousWeightDate
      )}`;
    },
    showWeightLoss() {
      return this.unplannedWeightLoss === YesNoUnsure.Enum.Yes;
    }
  },
  watch: {
    weightDiffPercent(v) {
      if (this.subjectiveUsePercent && this.automaticCalc) {
        // use calcualted % to be same as weightLossEnum
        if (v <= -10) this.updateWeightLossPercent(Weightloss.Enum.High);
        else if (v <= -5) this.updateWeightLossPercent(Weightloss.Enum.Medium);
        else this.updateWeightLossPercent(Weightloss.Enum.Low);
      }
    },
    weightDiffKg(v) {
      if (!this.subjectiveUsePercent && this.automaticCalc) {
        if (v <= 0) {
          this.updateWeightLoss(-v);
          this.updateUnplannedWeightLoss(YesNoUnsure.Enum.Yes);
        } else {
          this.updateUnplannedWeightLoss(YesNoUnsure.Enum.No);
        }
      }
    }
  },
  methods: {
    updateWeightLossPercent(v) {
      this.$emit("update:weightLossPercent", v);
    },
    updateWeightLoss(v) {
      this.$emit("update:weightLoss", v);
    },
    updateUnplannedWeightLoss(v) {
      this.$emit("update:unplannedWeightLoss", v);
    }
  }
};
</script>
