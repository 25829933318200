<template>
  <b-field grouped :message="message">
    <b-radio v-model="newValue" :name="name" :native-value="yesNoUnsure.Yes" required :disabled="disabled">Yes</b-radio>
    <b-radio v-model="newValue" :name="name" :native-value="yesNoUnsure.No" :disabled="disabled">No</b-radio>
    <b-radio v-model="newValue" :name="name" :native-value="yesNoUnsure.Unsure" :disabled="disabled">Unsure</b-radio>
  </b-field>
</template>

<script>
import YesNoUnsure from "@/enums/yesNoUnsure";

export default {
  name: "YesNoUnsurePicker",
  props: {
    value: { type: Number, default: null },
    name: { type: String, required: true },
    message: { type: String, required: false },
    disabled: { type: Boolean, required: false }
  },
  data() {
    return {
      newValue: null,
      yesNoUnsure: YesNoUnsure.Enum
    };
  },
  watch: {
    value(newValue) {
      this.newValue = newValue;
    },
    newValue(newValue) {
      this.$emit("input", newValue);
    }
  },
  created() {
    this.newValue = this.value;
  }
};
</script>
