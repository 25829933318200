<template>
  <AssessmentForm
    :assessmentDate="form.assessmentDate"
    :score="score"
    :rating="rating"
    :assessmentId="form.assessmentId"
    :assessmentType="assessmentType"
    :form="form"
    :readonly="readonly"
    :saveDisabled="saveDisabled"
    @setResidentData="setResidentData"
    @setAssessmentDate="setAssessmentDate"
    @mapServerData="mapServerData"
    @dataSaved="dataSaved"
  >
    <BMIComponent :form="form" @setBmi="v => (bmi = v)" :readonly="readonly" />
    <div class="columns">
      <div class="column">
        <WeightLoss
          :disabled="readonly"
          :currentWeight="form.weight"
          :previousWeight="form.previousWeight"
          :previousWeightDate="form.previousWeightDate"
          :weightLossPercemt="form.weightLoss"
          @update:weightLossPercent="v => (form.weightLoss = v)"
        />
      </div>
      <div class="column">
        <b-field label="Acutely Ill" horizontal class="label-long">
          <b-checkbox v-model="form.acutelyIll" :disabled="readonly" />
        </b-field>
      </div>
      <div class="column">
        <b-field label="No nutritional intake for > 5 days" horizontal class="label-long">
          <b-checkbox v-model="form.noIntake5Days" :disabled="readonly">(has been or is likely to be)</b-checkbox>
        </b-field>
      </div>
    </div>
  </AssessmentForm>
</template>

<script>
import AssessmentForm from "@/views/assessments/AssessmentForm";
import BMIComponent from "@/components/assessments/BMIComponent";
import WeightLoss from "@/components/assessments/WeightLoss";

import AssessmentType from "@/enums/assessmentType";
import Risk from "@/enums/risk";

import assessmentMixin from "@/mixins/assessmentMixin";

export default {
  components: { AssessmentForm, BMIComponent, WeightLoss },
  mixins: [assessmentMixin],
  data() {
    return {
      assessmentType: AssessmentType.Enum.Must,
      form: this.getClearFormObject(),
      bmi: null
    };
  },

  computed: {
    bmiScore() {
      if (this.bmi > 20) return 0;
      if (this.bmi >= 18.5) return 1;
      return 2;
    },

    score() {
      var score = this.bmiScore + this.form.weightLoss;
      score += this.form.acutelyIll && this.form.noIntake5Days ? 2 : 0;
      return score;
    },
    rating() {
      const score = this.score;
      if (score === 0) return Risk.Enum.LowRisk;
      if (score === 1) return Risk.Enum.MediumRisk;
      return Risk.Enum.HighRisk;
    }
  },

  methods: {
    getClearFormObject() {
      return {
        assessmentId: this.id,
        residentId: null,
        assessmentDate: new Date(),
        gender: null,
        age: null,

        height: null,
        weight: null,
        heightId: null,
        weightId: null,
        heightDate: null,
        weightDate: null,

        previousWeightId: null,
        previousWeight: null,
        previousWeightDate: null,

        weightLoss: null,
        acutelyIll: false,
        noIntake5Days: false
      };
    }
  },
  mounted() {
    // due to so few fields, we may not hvae any changes to make but still wantto save, so need to setDirty
    this.setDirty();
  }
};
</script>
