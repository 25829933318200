<template>
  <div>
    <ObsDialog
      :icon="Icons.obsHeight"
      url="obs_height/"
      obName="Height"
      unit="cm"
      :observation="observation"
      :readonly="readonly"
      :canAddNew="canAddNew"
      :graphOnly="graphOnly"
      @close="$emit('close')"
      @refresh="v => $emit('refresh', v)"
      @select="v => (this.observation = v)"
      @clear="resetForm"
      :loadOnOpen="loadOnOpen"
      @mapServerData="mapServerData"
    >
      <b-field horizontal>
        <b-radio v-model="observation.directMeasurement" name="directMeasurement" :native-value="true" :disabled="readonly" required>
          Direct Measurement
        </b-radio>
        <b-radio v-model="observation.directMeasurement" name="directMeasurement" :native-value="false" :disabled="readonly" required>
          Using Ulna
        </b-radio>
      </b-field>

      <template v-if="!observation.directMeasurement">
        <LabelSuffix label="Ulna Length" suffix="cm">
          <b-numberinput
            expanded
            v-model="observation.ulnaLength"
            :controls="false"
            required
            :disabled="readonly"
            v-focus
            min="15"
            max="40"
            :step="0.1"
            class="inputFixedWidth-1"
          />
        </LabelSuffix>

        <b-field label="Age" horizontal message="At date of measurement">
          <b-input v-model="observation.age" disabled class="inputFixedWidth-1" />
          <b-field label="Gender" horizontal class="field-nested label-long">
            <PersonGender v-model="observation.gender" :disabled="true" />
          </b-field>
        </b-field>
      </template>

      <!-- Nested <b-field> so that on mobile the unit label stays on same line
       max="300" causes the default width to shrink to only whats required  -->
      <LabelSuffix label="Height" suffix="cm">
        <b-numberinput
          expanded
          v-model="observation.value"
          :controls="false"
          :required="!readonly && observation.directMeasurement"
          :disabled="readonly || !observation.directMeasurement"
          v-focus
          min="50"
          max="250"
          class="inputFixedWidth-1"
        />
      </LabelSuffix>
    </ObsDialog>
  </div>
</template>

<script>
import ObsDialog from "@/components/observations/ObsDialog.vue";
import LabelSuffix from "@/components/LabelSuffix.vue";
import obsMixin from "@/mixins/obsMixin.js";
import PersonGender from "@/components/profile/PersonGender.vue";
import { mapGetters } from "vuex";
import Gender from "@/enums/gender";

export default {
  mixins: [obsMixin],
  components: { ObsDialog, LabelSuffix, PersonGender },
  computed: {
    ...mapGetters("resident", ["residentAge", "residentMedicalGender", "residentDob"])
  },
  watch: {
    "observation.ulnaLength": {
      handler(u) {
        // prettier-ignore
        if (u) {
          this.observation.value = Math.round(
            this.observation.gender === Gender.Enum.Male
              ? this.observation.age < 65
                ? 79.2 + 3.6 * u
                : 86.3 + 3.15 * u
              : this.observation.age < 65
                ? 95.6 + 2.77 * u
                : 80.4 + 3.25 * u
          );
        }
      }
    },
    "observation.directMeasurement": {
      handler(v) {
        // For Admin Edit, if type changed we need to update the resident info
        if (!v && !this.observation.age) {
          this.observation.age = this.residentAge;
          this.observation.gender = this.residentMedicalGender;
        }
      }
    }
  },
  methods: {
    getClearFormObject() {
      return {
        observationId: 0,
        residentId: this.$store.getters["resident/residentId"],
        value: null,
        notes: null,

        directMeasurement: true,
        ulnaLength: null,
        age: this.residentAge,
        gender: this.residentMedicalGender
      };
    }
  }
};
</script>
