const WeightLossEnumm = {
  Low: 0,
  Medium: 1,
  High: 2
};

export default {
  Enum: WeightLossEnumm,
  Lookup: [
    { id: WeightLossEnumm.Low, value: "< 5%" },
    { id: WeightLossEnumm.Medium, value: "5-10%" },
    { id: WeightLossEnumm.High, value: "> 10%" }
  ]
};
