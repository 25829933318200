<template>
  <div class="columns">
    <div class="column">
      <ValidationProvider :rules="heightRules" v-slot="{ errors }">
        <b-field
          label="Height (cm)"
          horizontal
          class="label-long"
          :type="{ 'is-danger': errors[0] }"
          :message="recordedDate(form.heightDate, errors)"
        >
          <b-numberinput
            :value="form.height"
            :controls="false"
            step="0.01"
            required
            disabled
            @click.native="editHeight"
            :loading="loadingHeight"
          />
          <b-icon
            v-if="!readonly"
            icon="plus-box-multiple"
            size="is-default"
            type="is-primary"
            class="clickable"
            @click.native="addHeight"
          />
        </b-field>
      </ValidationProvider>
    </div>
    <div class="column">
      <ValidationProvider :rules="weightRules" v-slot="{ errors }">
        <b-field
          label="Weight (kg)"
          horizontal
          class="label-long"
          :type="{ 'is-danger': errors[0] }"
          :message="recordedDate(form.weightDate, errors)"
        >
          <b-numberinput
            :value="form.weight"
            :controls="false"
            step="0.01"
            required
            disabled
            @click.native="editWeight"
            :loading="loadingWeight"
          />
          <b-icon
            v-if="!readonly"
            icon="plus-box-multiple"
            size="is-default"
            type="is-primary"
            class="clickable"
            @click.native="addWeight"
          />
        </b-field>
      </ValidationProvider>
    </div>
    <div class="column">
      <b-field label="BMI" horizontal class="label-long">
        <b-input :value="bmiReading" disabled />
      </b-field>
    </div>
  </div>
</template>

<script>
import BmiRatingEnum from "@/enums/bmiRating";
import formatting from "@/common/formatting";
import HeightDialog from "@/components/observations/HeightDialog";
import WeightDialog from "@/components/observations/WeightDialog";
import DatesMixin from "@/mixins/datesMixin";
import OrgConfig from "@/enums/orgconfig";
import { mapGetters } from "vuex";

export default {
  mixins: [DatesMixin],
  props: {
    form: { type: Object, default: () => {} },
    readonly: { type: Boolean, default: false }
  },
  data() {
    return {
      loadingWeight: false,
      loadingHeight: false,
      heightRules: { heightRequired: true, height: [50, 250] }
    };
  },
  computed: {
    ...mapGetters("orgconfig", ["defaults"]),
    daysSinceWeight() {
      return this.daysSince(this.form.weightDate, this.form.assessmentDate);
    },
    weightRules() {
      return {
        weightRequired: true,
        weight: [20, 200],
        weightDays: [this.daysSinceWeight, this.defaults[OrgConfig.Enum.Obs_RecentWeightDays].value]
      };
    },
    bmi() {
      const h = this.form.height / 100; // convert to metres
      const w = this.form.weight;
      const bmi = h > 0 && w > 0 ? formatting.round(w / (h * h)) : null;
      this.$emit("setBmi", bmi);
      return bmi;
    },
    bmiRating() {
      var rating = BmiRatingEnum.Enum.Average;
      if (this.bmi < 20) rating = BmiRatingEnum.Enum.BelowAverage;
      else if (this.bmi < 25) rating = BmiRatingEnum.Enum.Average;
      else if (this.bmi < 30) rating = BmiRatingEnum.Enum.AboveAverage;
      else rating = BmiRatingEnum.Enum.Obese;
      this.$emit("setRating", rating);
      return rating;
    },
    bmiReading() {
      return this.bmi ? `${this.bmi} (${BmiRatingEnum.Lookup[this.bmiRating]})` : "";
    }
  },
  methods: {
    addHeight() {
      this.$buefy.modal.open({
        parent: this,
        component: HeightDialog,
        hasModalCard: true,
        events: { refresh: this.gotNewHeight }
      });
    },
    editHeight() {
      if (!this.form?.heightId) this.addHeight();
      else {
        this.$buefy.modal.open({
          parent: this,
          component: HeightDialog,
          hasModalCard: true,
          props: { observationId: this.form.heightId },
          events: { refresh: this.gotNewHeight }
        });
      }
    },
    gotNewHeight(data) {
      this.form.height = data?.value;
      this.form.heightId = data?.observationId;
      this.form.heightDate = data?.obsDate;
    },
    addWeight() {
      this.$buefy.modal.open({
        parent: this,
        component: WeightDialog,
        hasModalCard: true,
        events: { refresh: this.refreshWeighs }
      });
    },
    editWeight() {
      if (!this.form?.weightId) this.addWeight();
      else {
        this.$buefy.modal.open({
          parent: this,
          component: WeightDialog,
          hasModalCard: true,
          props: { observationId: this.form.weightId },
          events: { refresh: this.gotNewWeight }
        });
      }
    },
    gotNewWeight(data) {
      this.form.weight = data?.value;
      this.form.weightId = data?.observationId;
      this.form.weightDate = data?.obsDate;
    },
    gotPreviousWeight(data) {
      this.form.previousWeight = data?.value;
      this.form.previousWeightId = data?.observationId;
      this.form.previousWeightDate = data?.obsDate;
    },
    recordedDate(date, errors) {
      return errors[0] || (date ? "Recorded: " + this.shortFormatDate(date) : null);
    },
    refreshWeighs() {
      const residentId = this.$store.getters["resident/residentId"];
      if (!residentId) return;
      this.loadingWeight = false;
      this.$http
        .get(`observations?residentId=${residentId}&weightsOnly=true`)
        .then(r => {
          this.gotNewWeight(r.data.weight);
          this.gotPreviousWeight(r.data.previousForWeightLoss);
        })
        .catch(e => this.$alerts.showErrorAlert(e, "Error retrieving Weights"))
        .finally(() => {
          this.loadingWeight = false;
        });
    }
  },
  mounted() {
    // loads obs for new one only,
    if (!this.form.assessmentId) {
      const residentId = this.$store.getters["resident/residentId"];
      if (!residentId) return;
      this.loadingHeight = !this.form.height;
      this.loadingWeight = !this.form.weight;
      this.$http
        .get(`observations?residentId=${residentId}&bmiOnly=true`)
        .then(r => {
          if (!this.form.height) this.gotNewHeight(r.data.height);
          if (!this.form.weight) this.gotNewWeight(r.data.weight);
          this.gotPreviousWeight(r.data.previousForWeightLoss);
          this.$emit("setNotDirty");
        })
        .catch(e => this.$alerts.showErrorAlert(e, "Error retrieving BMI Obs"))
        .finally(() => {
          this.loadingHeight = false;
          this.loadingWeight = false;
        });
    }
  }
};
</script>
